import { FC } from "react";
import "./LoginHeader.css";

const LoginHeader: FC = () => {
  return (
    <div className="login-header-container">
      <h1 className="login-header-title">Sign In</h1>
      <p className="login-header-text">Sign in to continue to Chat</p>
    </div>
  );
};

export default LoginHeader;
