import { FC } from "react";
import Auth from "../Auth/Auth";
import Main from "../Main/Main";
import Sidebar from "../Sidebar/Sidebar";
import "./Layout.css";

const Layout: FC = () => {
  return (
    <div className="layout-container">
      <Sidebar />
      <Main />
    </div>
  );
};

export default Auth({ component: Layout });
